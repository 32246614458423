<template>
    <div class="PricingSelection" id="pricing-selection">
        <h2 class="Headline">Wählen Sie Ihr Paket oder einzelne Module</h2>
        <p>
            Buchen Sie einzelne Module, um Ihr Taxi-Unternehmen zu optimieren.
        </p>
        <div class="PricingSelection-Wrapper">
            <div class="Products-Section-Wrapper">
                <!-- <h2>
                Unsere Pakete
            </h2> -->
                <div class="Products-Section">
                    <Card
                        class="Package-Card"
                        v-for="product in products"
                        :key="product.id"
                        :class="{ selected: selectedProduct === product.id }"
                    >
                        <h4>{{ product.name }}</h4>
                        <ul>
                            <li v-for="feature in product.features" :key="feature">
                                <span>{{ feature }}</span>
                            </li>
                        </ul>
                        <h2 class="Price">
                            {{ product.price }}€
                            <br />
                            <span class="Info">pro Monat / pro Taxi</span>
                        </h2>
                        <Button
                            variant="warning"
                            @onClick="selectProduct(product.id)"
                            style="width: 100%;"
                        >
                            {{ selectedProduct === product.id ? 'Ausgewählt' : 'Wählen' }}
                        </Button>
                    </Card>
                </div>
            </div>
            <div class="Modules-Section-Wrapper">
                <h4>Wählen Sie einzelne Module</h4>
                <div class="Modules-Section">
                    <div
                        class="Module-Item"
                        v-for="module in modules"
                        :key="module.id"
                        :class="[
                            { selected: selectedModules.includes(module.id) },
                            { disabled: isModuleDisabled(module.name) },
                        ]"
                    >
                        <i :class="module.icon"></i>
                        <h5>{{ module.name }}</h5>
                        <p>{{ module.description }}</p>
                        <div class="Module-Price">
                            {{ priceToEuroString(module.price * 100) }}
                            <br />
                            <span class="Info">pro Monat / pro Taxi</span>
                        </div>
                        <input
                            type="checkbox"
                            :id="module.id"
                            v-model="selectedModules"
                            :value="module.id"
                            :disabled="isModuleDisabled(module.name)"
                        />
                        <label :for="module.id">{{
                            isModuleDisabled(module.name) ? 'Im Paket enthalten' : ''
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="Total-Price">
            <h3 v-if="totalPrice > 0">
                Fortfahen mit Auswahl
            </h3>
            <Button variant="warning" @onClick="handleUpgrade" v-tooltip="'Weiter zum Checkout'">
                Gesamtpreis: {{ priceToEuroString(totalPrice * 100) }}

                <span class="Info">pro Monat / pro Taxi</span>
            </Button>
        </div>
    </div>
</template>

<script>
import { priceToEuroString } from '@/lib/helper';
import Card from '@/components/Card.vue';
import Button from '@/components/widgets/Button.vue';

export default {
    components: {
        Card,
        Button,
    },
    data() {
        return {
            priceToEuroString,
            selectedProduct: 'starter',
            selectedModules: [],
            products: [
                {
                    id: 'starter',
                    name: 'TaxiFusion Starter',
                    features: [
                        'Allround-Lösung für Taxi-Betriebe',
                        'Integrierte Fahrer-App',
                        'Internetbasierte Verwaltung',
                        'Erfüllung gesetzlicher Anforderungen',
                        'Spezifische Anpassung für Taxibetriebe',
                    ],
                    price: 16,
                },
                // {
                //     id: 'pro',
                //     name: 'TaxiFusion Pro',
                //     features: [
                //         'Alle Funktionen in TaxiFusion Starter',
                //         'Performance-Analyse',
                //         'Reminder-Funktionen',
                //         'Fahrtenbuch',
                //     ],
                //     price: 24.99,
                // },
                // {
                //     id: 'elite',
                //     name: 'TaxiFusion Elite',
                //     features: [
                //         'Alle Funktionen in TaxiFusion Pro',
                //         'Werkstatt-Service',
                //         'Tracking der Fahrzeuge (Mit F4)',
                //         'Bereitstellung von Daten',
                //         'Fahrerabrechnung',
                //         'Rücklagenberechnung',
                //     ],
                //     subFeatures: ['Performance-Analyse', 'Reminder-Funktionen', 'Fahrtenbuch'],
                //     price: 29.99,
                // },
            ],
            modules: [
                {
                    id: 'performance-analysis',
                    name: 'Performance-Analyse',
                    description:
                        'Übersicht der Leistung Ihrer Fahrer und Fahrzeuge für verbesserte betriebliche Effizienz.',
                    price: 1.0,
                    icon: 'ri-pie-chart-line',
                },
                {
                    id: 'reminder-functions',
                    name: 'Reminder-Funktionen',
                    description:
                        'Erinnerungen für Inspektionen, TÜV, P-Schein-Verlängerung und mehr.',
                    price: 3.0,
                    icon: 'ri-calendar-todo-line',
                },
                // {
                //     id: 'logbook',
                //     name: 'Fahrtenbuch',
                //     description:
                //         'Fahrtenbuch für die Finanzbehörde zur Reduzierung von Verwaltungsaufwand.',
                //     price: 2.0,
                //     icon: 'ri-book-line',
                // },
                // {
                //     id: 'workshop-service',
                //     name: 'Werkstatt-Service',
                //     description:
                //         'Überblick über Reparaturen und Wartungen zur Reduzierung von Ausfallzeiten und Verbesserung der Fahrzeuglebensdauer.',
                //     price: 4.0,
                //     icon: 'ri-tools-line',
                // },
                // {
                //     id: 'vehicle-tracking',
                //     name: 'Tracking der Fahrzeuge (Mit F4)',
                //     description:
                //         'Live-Tracking der Fahrzeuge für verbessertes Management und Effizienz.',
                //     price: 6.0,
                //     icon: 'ri-map-pin-line',
                // },
                // {
                //     id: 'data-provision',
                //     name: 'Bereitstellung von Daten',
                //     description: 'Erfüllung der Mobilitätsdatenverordnung ohne zusätzliche Kosten.',
                //     price: 3.5,
                //     icon: 'ri-database-line',
                // },
                // {
                //     id: 'driver-accounting',
                //     name: 'Fahrerabrechnung',
                //     description:
                //         'Vereinfachte und effiziente Abrechnungsprozesse sparen Zeit und reduzieren Fehler.',
                //     price: 4.5,
                //     icon: 'ri-account-pin-box-line',
                // },
                // {
                //     id: 'reserve-calculation',
                //     name: 'Rücklagenberechnung',
                //     description: 'Finanzielle Planung und Budgetierung für Investitionen.',
                //     price: 2.5,
                //     icon: 'ri-calculator-line',
                // },
            ],
        };
    },
    computed: {
        totalPrice() {
            const selectedProduct = this.products.find(pkg => pkg.id === this.selectedProduct);
            const modulePrices = this.selectedModules.map(moduleId => {
                const module = this.modules.find(mod => mod.id === moduleId);
                return module ? module.price : 0;
            });
            const total =
                modulePrices.reduce((sum, price) => sum + price, 0) +
                (selectedProduct ? selectedProduct.price : 0);
            return total.toFixed(2);
        },
        selectedProductFeatures() {
            if (!this.selectedProduct) return [];
            const selectedProduct = this.products.find(pkg => pkg.id === this.selectedProduct);
            const features = selectedProduct
                ? [...selectedProduct.features, ...(selectedProduct.subFeatures || [])]
                : [];
            return features;
        },
    },
    methods: {
        selectProduct(packageId) {
            this.selectedProduct = packageId;
            this.selectedModules = [];
        },
        isModuleDisabled(moduleName) {
            return this.selectedProductFeatures.includes(moduleName);
        },
        handleUpgrade() {
            this.$router.push('/sign-in');
        },
    },
    mounted() {
        window.scrollTo(0, 0);
        this.$emit('onFinishLoading');
    },
};
</script>

<style lang="scss">
.PricingSelection {
    padding: 60px 20px 120px;

    .Headline {
        margin-bottom: 30px;
        font-size: 32px;
        font-weight: 700;
    }

    .Products-Section,
    .Modules-Section {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        margin-bottom: 50px;
        max-width: 1200px;
        margin: 0px auto;
    }

    hr {
        border: 0;
        border-top: 1px solid #bdbdbd;
        margin: 60px 0;
    }

    .Package-Card {
        padding: 20px;
        border-radius: 10px;
        transition: border-color 0.3s;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        &.selected {
            // border-color: #616c9f;
        }

        h4 {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 700;
        }

        ul {
            text-align: left;
            margin: 20px 0;

            li {
                font-size: 16px;
                margin-bottom: 10px;
                margin-left: 20px;
                list-style-image: url('../../../assets/svg/checkmark.svg');

                span {
                    display: block;
                    transform: translate(6px, -6px);
                }
            }
        }

        .Price {
            font-size: 30px;
            margin-bottom: 20px;
            margin-top: auto;

            .Info {
                font-size: 14px;
            }
        }
    }

    .Module-Item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 2px solid #ddd;
        border-radius: 8px;
        width: 250px;
        cursor: pointer;
        transition: border-color 0.3s;
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            content: '';
            opacity: 0;
            transition: all 0.3s;
            background-color: #2d3453;
        }

        &.disabled {
            border-color: #ddd;
            opacity: 0.5;
            pointer-events: none;
        }

        &.selected {
            border-color: #2d3453;

            &::before {
                opacity: 1;
            }
        }

        &:hover {
            border-color: #616c9f;
        }

        p {
            font-size: 16px;
            margin-bottom: 20px;
        }

        i {
            font-size: 26px;
            margin-bottom: 10px;
            color: #313c6c;
        }

        h5 {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
        }

        .Module-Price {
            font-size: 24px;
            margin-bottom: 0px;
            margin-top: auto;
            position: relative;

            .Info {
                font-size: 14px;
            }
        }

        input[type='checkbox'] {
            margin: 0px;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .Total-Price {
        margin: 50px 0;
        font-weight: 700;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 10px;
        position: fixed;
        bottom: -50px;
        right: -0px;
        background-color: white;
        padding: 14px 20px 10px;
        box-shadow: 0px -20px 40px rgba(0, 0, 0, 0.1);
        border-top: 1px solid #ddd;
        width: calc(100% - 280px);
        margin-left: auto;

        .Button {
            margin-top: 20px;
            margin-right: 20px;

            .Info {
                margin-left: 10px;
                font-size: 14px;
            }
        }
    }
    .PricingSelection-Wrapper {
        display: flex;
        justify-content: center;
        gap: 40px;
    }
    .Products-Section-Wrapper,
    .Modules-Section-Wrapper {
        margin-top: 50px;

        > h2 {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}
</style>
