var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "PricingSelection",
    attrs: {
      "id": "pricing-selection"
    }
  }, [_c('h2', {
    staticClass: "Headline"
  }, [_vm._v("Wählen Sie Ihr Paket oder einzelne Module")]), _c('p', [_vm._v(" Buchen Sie einzelne Module, um Ihr Taxi-Unternehmen zu optimieren. ")]), _c('div', {
    staticClass: "PricingSelection-Wrapper"
  }, [_c('div', {
    staticClass: "Products-Section-Wrapper"
  }, [_c('div', {
    staticClass: "Products-Section"
  }, _vm._l(_vm.products, function (product) {
    return _c('Card', {
      key: product.id,
      staticClass: "Package-Card",
      class: {
        selected: _vm.selectedProduct === product.id
      }
    }, [_c('h4', [_vm._v(_vm._s(product.name))]), _c('ul', _vm._l(product.features, function (feature) {
      return _c('li', {
        key: feature
      }, [_c('span', [_vm._v(_vm._s(feature))])]);
    }), 0), _c('h2', {
      staticClass: "Price"
    }, [_vm._v(" " + _vm._s(product.price) + "€ "), _c('br'), _c('span', {
      staticClass: "Info"
    }, [_vm._v("pro Monat / pro Taxi")])]), _c('Button', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "variant": "warning"
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.selectProduct(product.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.selectedProduct === product.id ? 'Ausgewählt' : 'Wählen') + " ")])], 1);
  }), 1)]), _c('div', {
    staticClass: "Modules-Section-Wrapper"
  }, [_c('h4', [_vm._v("Wählen Sie einzelne Module")]), _c('div', {
    staticClass: "Modules-Section"
  }, _vm._l(_vm.modules, function (module) {
    return _c('div', {
      key: module.id,
      staticClass: "Module-Item",
      class: [{
        selected: _vm.selectedModules.includes(module.id)
      }, {
        disabled: _vm.isModuleDisabled(module.name)
      }]
    }, [_c('i', {
      class: module.icon
    }), _c('h5', [_vm._v(_vm._s(module.name))]), _c('p', [_vm._v(_vm._s(module.description))]), _c('div', {
      staticClass: "Module-Price"
    }, [_vm._v(" " + _vm._s(_vm.priceToEuroString(module.price * 100)) + " "), _c('br'), _c('span', {
      staticClass: "Info"
    }, [_vm._v("pro Monat / pro Taxi")])]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedModules,
        expression: "selectedModules"
      }],
      attrs: {
        "type": "checkbox",
        "id": module.id,
        "disabled": _vm.isModuleDisabled(module.name)
      },
      domProps: {
        "value": module.id,
        "checked": Array.isArray(_vm.selectedModules) ? _vm._i(_vm.selectedModules, module.id) > -1 : _vm.selectedModules
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.selectedModules,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = module.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedModules = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedModules = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedModules = $$c;
          }
        }
      }
    }), _c('label', {
      attrs: {
        "for": module.id
      }
    }, [_vm._v(_vm._s(_vm.isModuleDisabled(module.name) ? 'Im Paket enthalten' : ''))])]);
  }), 0)])]), _c('div', {
    staticClass: "Total-Price"
  }, [_vm.totalPrice > 0 ? _c('h3', [_vm._v(" Fortfahen mit Auswahl ")]) : _vm._e(), _c('Button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Weiter zum Checkout',
      expression: "'Weiter zum Checkout'"
    }],
    attrs: {
      "variant": "warning"
    },
    on: {
      "onClick": _vm.handleUpgrade
    }
  }, [_vm._v(" Gesamtpreis: " + _vm._s(_vm.priceToEuroString(_vm.totalPrice * 100)) + " "), _c('span', {
    staticClass: "Info"
  }, [_vm._v("pro Monat / pro Taxi")])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }